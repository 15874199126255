 
import { Vue, Component, Watch } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { Usuario } from "@/core/models/cadastros";
import { UsuarioService } from "@/core/services/cadastros";

@Component
export default class ListaUsuario extends mixins(Vue, ListPage) {
  item = new Usuario();
  service = new UsuarioService();

  sheet:boolean = false;
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;

  localBuscaRapida: string = "";
  titulo: string = 'Usuarios';
  subTitulo: string = 'Lista dos cadastros de usuários para uso no sistema';
  temBuscaRapida: boolean = true;
  icone: string = 'mdi-cash';  

  options: any = {
    itemsPerPage: 15 
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Login", value: "login" },
    { text: "Celular", value: "complementar.celular" },
    { text: "Email", value: "complementar.email" }
  ];
  
  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, this.filter, 'Complementar/Celular,Complementar/Email', 'Id, Login, Complementar', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    .finally(() => (this.loading = false));
  } 

  filter : {
    id: number ,
  } = {
    id:0 , 
  }

  Consultar(){
    this.options.page = 1;
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true

    //expands=> Complementar/celular,nome
    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search,columns, this.filter, 'Complementar/Celular,Complementar/Email', 'Id, Login, Complementar', '').then(
      (res) => { 
        this.lista = res.data.items
        this.totalLista = res.data.count;
        this.loading = false; 
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
    this.sheet = false;
  }

  ResetFiltro(){
    this.filter = {id:0}
  }
 
  Novo() {
    this.item = new Usuario();
    this.item.assinanteId = JSON.parse(localStorage.sessionApp.split(".")).dados.assinanteId;
    this.dialogCadastro = true;
  }
  
  Excluir(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          res => {
            if (res.status == 200) {
              return res;
            }
          },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        }); 
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    }).then(result => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    });
  }
  
  ResetarSenha(item){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja resetar a senha do registro atual?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return this.service.ResetarSenha(item.id).then(
        res => {
          if (res.status == 200) {
            return res;
          }
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
       }); 
    },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading()
    }).then(result => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    });
  }

  usuarios = [];

  mounted(){ 
    new UsuarioService().Listar(-1, -1, ['login'],[],'',[], '', '' , 'Id, Login', '').then(
      res => {
        this.usuarios = res.data.items;
      }
    )
  }
}
